import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/content/seo';
import Layout from '../containers/layout';
import Partners from '../components/pages/partners';

const seo = {
  title: 'Partners',
  description: 'Partners page',
  keywords: ['Second Harvest Heartland'],
};

const PartnersPage = props => {
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <Partners logo={props.data.logo.childImageSharp.fixed.src} />
    </Layout>
  );
};

export default PartnersPage;

export const pageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "2nd-harvest-logo.png" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
