import React, { Component } from "react";
import ReactPlayer from "react-player";

import styles from "./video-player.module.less";

class VideoPlayer extends Component {
  render() {
    return (
      <div className={styles.playerWrapper}>
        <ReactPlayer
          className={styles.videoPlayer}
          url={this.props.url}
          width='100%'
          height='100%'
          youtubeConfig={{
            playerVars: {
              controls: 1,
              loop: 1,
              modestbranding: 1,
              rel: 0,
              color: "white",
            },
          }}
        />
      </div>
    );
  }
}

export default VideoPlayer;
