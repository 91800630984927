import React from 'react';

import { Page } from '../layout/page';
import HeadlineSection from '../layout/headline-section';
import VideoPlayer from '../layout/video-player';

const Partners = ({ logo }) => (
  <Page>
    <HeadlineSection
      justify='start'
      background='white'
      title={{ lead: 'Join Our', emphasis: 'Community Partners' }}
      subtitle='We have more impact when we work together.'
      button={{ label: 'Help Your Community', url: '/contact' }}
      supplement={
        <>
          <h5>LiveMenu is a proud partner of</h5>
          <p>
            <a href='https://www.2harvest.org'>
              <img src={logo} alt='Second Harvest Heartland' width={300} />
            </a>
          </p>
          <VideoPlayer url='http://www.youtube.com/embed/QL7ZExIpMCk' />
        </>
      }>
      <p>
        Although so many of our neighbors are hungry, there is enough food for
        everyone. Together with{' '}
        <a href='https://www.2harvest.org'>Second Harvest Heartland</a>, we can
        help connect that food to those who are struggling to make ends meet.
      </p>
    </HeadlineSection>
  </Page>
);

export default Partners;
